/* tslint:disable */
/* eslint-disable */
/**
 * compliance-api API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TeamMemberDto
 */
export interface TeamMemberDto {
    /**
     * 
     * @type {string}
     * @memberof TeamMemberDto
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof TeamMemberDto
     */
    designation: string;
}

export function TeamMemberDtoFromJSON(json: any): TeamMemberDto {
    return TeamMemberDtoFromJSONTyped(json, false);
}

export function TeamMemberDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamMemberDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fullName': json['fullName'],
        'designation': json['designation'],
    };
}

export function TeamMemberDtoToJSON(value?: TeamMemberDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fullName': value.fullName,
        'designation': value.designation,
    };
}

