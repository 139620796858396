import { useEffect, useMemo, useRef } from "react";
import { useTheme } from "@fluentui/react";
import View from "ol/View";
import WKT from "ol/format/WKT";
import GeometryType from "ol/geom/GeometryType";
import OlDraw, { DrawEvent } from "ol/interaction/Draw";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import Graticule from "ol/layer/Graticule";
import { OSM } from "ol/source";
import VectorSource from "ol/source/Vector";
import Style from "ol/style/Style";
import Map from "ol/Map";
import Circle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import { transform, transformExtent } from "ol/proj";
import { Attribution, Zoom, ZoomToExtent } from "ol/control";

const center = transform(
  [-58.939265910847396, 4.871915245504116],
  "EPSG:4326",
  "EPSG:3857"
);

const extent = transformExtent(
  [
    -61.39671280978678, 1.1858201899568914, -56.48181901190802,
    8.558010301051342,
  ],
  "EPSG:4326",
  "EPSG:3857"
);

type DrawFeatureProps = {
  type: string;
  height: number;
  featureWkt: string | undefined;
  onDrawEnd: (featureWkt: string) => void;
};

const DrawFeature: React.FC<DrawFeatureProps> = (props) => {
  const { type, height, featureWkt, onDrawEnd } = props;
  const mapRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const [osmSource, drawSource] = useMemo(
    () => [new OSM(), new VectorSource()],
    []
  );

  const [osmLayer, graticuleLayer, drawLayer] = useMemo(() => {
    const osmLayer = new TileLayer({
      source: osmSource,
    });

    const drawLayer = new VectorLayer({
      source: drawSource,
    });

    const graticuleLayer = new Graticule({
      showLabels: true,
      strokeStyle: new Stroke({
        color: theme.palette.neutralSecondary,
        width: 2,
        lineDash: [0.5, 4],
      }),
    });

    return [osmLayer, graticuleLayer, drawLayer];
  }, [osmSource, drawSource, theme]);

  const drawInteraction = useMemo(() => {
    const drawInteraction = new OlDraw({
      source: drawSource,
      type,
    });

    drawInteraction.on("drawstart", () => {
      drawSource.clear();
    });

    return drawInteraction;
  }, [drawSource, type]);

  const map = useMemo(() => {
    const map = new Map({
      view: new View({
        center,
        extent,
        showFullExtent: true,
        zoom: 6,
      }),
      controls: [
        new Zoom(),
        new ZoomToExtent({ extent }),
        new Attribution({ collapsible: false }),
      ],
      layers: [osmLayer, graticuleLayer, drawLayer],
    });

    map.addInteraction(drawInteraction);

    return map;
  }, [osmLayer, graticuleLayer, drawLayer, drawInteraction]);

  // styles
  useEffect(() => {
    switch (type) {
      case GeometryType.POINT:
        drawLayer.setStyle(
          new Style({
            image: new Circle({
              radius: 7.5,
              fill: new Fill({
                color: theme.palette.themeSecondary,
              }),
            }),
          })
        );

        drawInteraction.getOverlay().setStyle(
          new Style({
            image: new Circle({
              radius: 5,
              fill: new Fill({
                color: theme.palette.themeSecondary,
              }),
            }),
          })
        );

        break;

      case GeometryType.POLYGON:
        drawLayer.setStyle(
          new Style({
            stroke: new Stroke({
              color: theme.palette.themeSecondary,
              width: 5,
            }),
          })
        );

        drawInteraction.getOverlay().setStyle(
          new Style({
            stroke: new Stroke({
              color: theme.palette.themeSecondary,
              width: 3,
            }),
          })
        );

        break;

      default:
        break;
    }
  }, [drawLayer, drawInteraction, theme, type]);

  useEffect(() => {
    if (!featureWkt) return;

    drawSource.clear();
    const wkt = new WKT();
    const feature = wkt.readFeature(featureWkt);
    drawSource.addFeature(feature);
  }, [featureWkt, drawSource]);

  useEffect(() => {
    const listener = (event: DrawEvent) => {
      const wkt = new WKT();
      const pointWkt = wkt.writeFeature(event.feature);
      onDrawEnd(pointWkt);
    };

    drawInteraction.on("drawend", listener);

    return () => drawInteraction.un("drawend", listener);
  }, [drawInteraction, onDrawEnd]);

  useEffect(() => {
    if (!mapRef.current) return;

    map.setTarget(mapRef.current);

    return () => map.setTarget(undefined);
  }, [map, mapRef]);

  return <div ref={mapRef} style={{ height }} />;
};

export default DrawFeature;
