import React, { useCallback, useContext } from "react";
import { DefaultButton, PrimaryButton, Text } from "@fluentui/react";

import { MonitoringExerciseResourceApi } from "../api";
import MonitoringExerciseForm, {
  MonitoringExerciseFormActionsContainer,
  MonitoringExerciseFormContext,
  MonitoringExerciseFormProvider,
} from "./MonitoringExerciseForm";

const CreateMonitoringExercise: React.FC = () => {
  return (
    <MonitoringExerciseFormProvider>
      {/* Title */}
      <Text variant="xxLarge">Create monitoring exercise</Text>

      {/* Form */}
      <MonitoringExerciseForm />

      {/* Actions */}
      <MonitoringExerciseFormActionsContainer>
        <Actions />
      </MonitoringExerciseFormActionsContainer>
    </MonitoringExerciseFormProvider>
  );
};

const Actions: React.FC = () => {
  const { dto, done } = useContext(MonitoringExerciseFormContext);

  const createMonitoringExercise = useCallback(async () => {
    if (!dto) return;

    const api = new MonitoringExerciseResourceApi();
    await api.createMonitoringExercise({ monitoringExerciseRequestDto: dto });
    done();
  }, [dto, done]);

  return (
    <React.Fragment>
      <PrimaryButton disabled={!dto} onClick={createMonitoringExercise}>
        Create
      </PrimaryButton>
      <DefaultButton onClick={() => done()}>Cancel</DefaultButton>
    </React.Fragment>
  );
};

export default CreateMonitoringExercise;
