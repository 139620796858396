/* tslint:disable */
/* eslint-disable */
/**
 * compliance-api API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActivityDto,
    ActivityDtoFromJSON,
    ActivityDtoFromJSONTyped,
    ActivityDtoToJSON,
} from './ActivityDto';
import {
    BusinessesDto,
    BusinessesDtoFromJSON,
    BusinessesDtoFromJSONTyped,
    BusinessesDtoToJSON,
} from './BusinessesDto';
import {
    FacilityDto,
    FacilityDtoFromJSON,
    FacilityDtoFromJSONTyped,
    FacilityDtoToJSON,
} from './FacilityDto';
import {
    InfluentialPersonDto,
    InfluentialPersonDtoFromJSON,
    InfluentialPersonDtoFromJSONTyped,
    InfluentialPersonDtoToJSON,
} from './InfluentialPersonDto';
import {
    MiningOperationDto,
    MiningOperationDtoFromJSON,
    MiningOperationDtoFromJSONTyped,
    MiningOperationDtoToJSON,
} from './MiningOperationDto';
import {
    PopulationDto,
    PopulationDtoFromJSON,
    PopulationDtoFromJSONTyped,
    PopulationDtoToJSON,
} from './PopulationDto';
import {
    TeamMemberDto,
    TeamMemberDtoFromJSON,
    TeamMemberDtoFromJSONTyped,
    TeamMemberDtoToJSON,
} from './TeamMemberDto';

/**
 * 
 * @export
 * @interface MonitoringExerciseDto
 */
export interface MonitoringExerciseDto {
    /**
     * 
     * @type {string}
     * @memberof MonitoringExerciseDto
     */
    id: string | null;
    /**
     * 
     * @type {Array<TeamMemberDto>}
     * @memberof MonitoringExerciseDto
     */
    team: Array<TeamMemberDto>;
    /**
     * 
     * @type {string}
     * @memberof MonitoringExerciseDto
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringExerciseDto
     */
    polygonWkt?: string;
    /**
     * 
     * @type {Date}
     * @memberof MonitoringExerciseDto
     */
    date: Date;
    /**
     * 
     * @type {Array<PopulationDto>}
     * @memberof MonitoringExerciseDto
     */
    population: Array<PopulationDto>;
    /**
     * 
     * @type {Array<ActivityDto>}
     * @memberof MonitoringExerciseDto
     */
    activities: Array<ActivityDto>;
    /**
     * 
     * @type {Array<FacilityDto>}
     * @memberof MonitoringExerciseDto
     */
    facilities: Array<FacilityDto>;
    /**
     * 
     * @type {Array<BusinessesDto>}
     * @memberof MonitoringExerciseDto
     */
    businesses: Array<BusinessesDto>;
    /**
     * 
     * @type {Array<InfluentialPersonDto>}
     * @memberof MonitoringExerciseDto
     */
    influentialPersons: Array<InfluentialPersonDto>;
    /**
     * 
     * @type {string}
     * @memberof MonitoringExerciseDto
     */
    feuds?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringExerciseDto
     */
    otherInformation?: string;
    /**
     * 
     * @type {Array<MiningOperationDto>}
     * @memberof MonitoringExerciseDto
     */
    miningOperations: Array<MiningOperationDto>;
    /**
     * 
     * @type {Date}
     * @memberof MonitoringExerciseDto
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof MonitoringExerciseDto
     */
    updatedAt: Date;
}

export function MonitoringExerciseDtoFromJSON(json: any): MonitoringExerciseDto {
    return MonitoringExerciseDtoFromJSONTyped(json, false);
}

export function MonitoringExerciseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringExerciseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'team': ((json['team'] as Array<any>).map(TeamMemberDtoFromJSON)),
        'location': json['location'],
        'polygonWkt': !exists(json, 'polygonWkt') ? undefined : json['polygonWkt'],
        'date': (new Date(json['date'])),
        'population': ((json['population'] as Array<any>).map(PopulationDtoFromJSON)),
        'activities': ((json['activities'] as Array<any>).map(ActivityDtoFromJSON)),
        'facilities': ((json['facilities'] as Array<any>).map(FacilityDtoFromJSON)),
        'businesses': ((json['businesses'] as Array<any>).map(BusinessesDtoFromJSON)),
        'influentialPersons': ((json['influentialPersons'] as Array<any>).map(InfluentialPersonDtoFromJSON)),
        'feuds': !exists(json, 'feuds') ? undefined : json['feuds'],
        'otherInformation': !exists(json, 'otherInformation') ? undefined : json['otherInformation'],
        'miningOperations': ((json['miningOperations'] as Array<any>).map(MiningOperationDtoFromJSON)),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function MonitoringExerciseDtoToJSON(value?: MonitoringExerciseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'team': ((value.team as Array<any>).map(TeamMemberDtoToJSON)),
        'location': value.location,
        'polygonWkt': value.polygonWkt,
        'date': (value.date.toISOString().substr(0,10)),
        'population': ((value.population as Array<any>).map(PopulationDtoToJSON)),
        'activities': ((value.activities as Array<any>).map(ActivityDtoToJSON)),
        'facilities': ((value.facilities as Array<any>).map(FacilityDtoToJSON)),
        'businesses': ((value.businesses as Array<any>).map(BusinessesDtoToJSON)),
        'influentialPersons': ((value.influentialPersons as Array<any>).map(InfluentialPersonDtoToJSON)),
        'feuds': value.feuds,
        'otherInformation': value.otherInformation,
        'miningOperations': ((value.miningOperations as Array<any>).map(MiningOperationDtoToJSON)),
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}

