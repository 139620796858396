import React, { useCallback, useContext, useEffect, useState } from "react";
import { DefaultButton, PrimaryButton, Text } from "@fluentui/react";

import { MonitoringExerciseResourceApi } from "../api";
import MonitoringExerciseForm, {
  MonitoringExerciseFormActionsContainer,
  MonitoringExerciseFormContext,
  MonitoringExerciseFormProvider,
} from "./MonitoringExerciseForm";
import { useParams } from "react-router-dom";

const UpdateMonitoringExercise: React.FC = () => {
  return (
    <MonitoringExerciseFormProvider>
      <FetchMonitoringExercise>
        {/* Title */}
        <Text variant="xxLarge">Edit monitoring exercise</Text>

        {/* Form */}
        <MonitoringExerciseForm />

        {/* Actions */}
        <MonitoringExerciseFormActionsContainer>
          <Actions />
        </MonitoringExerciseFormActionsContainer>
      </FetchMonitoringExercise>
    </MonitoringExerciseFormProvider>
  );
};

const FetchMonitoringExercise: React.FC = ({ children }) => {
  const [hasFetched, setHasFetched] = useState(false);
  const { setForm } = useContext(MonitoringExerciseFormContext);

  const { id } = useParams();

  useEffect(() => {
    if (!id) return;

    (async () => {
      const api = new MonitoringExerciseResourceApi();

      const [monitoringExerciseDto, documents] = await Promise.all([
        api.findMonitoringExercise({ id }),
        api.listDocuments({ id }),
      ]);

      setForm({ ...monitoringExerciseDto, documents });
      setHasFetched(true);
    })();
  }, [id, setForm, setHasFetched]);

  if (!hasFetched) return null;

  return <React.Fragment>{children}</React.Fragment>;
};

const Actions: React.FC = () => {
  const { id } = useParams();
  const { dto, done } = useContext(MonitoringExerciseFormContext);

  const updateMonitoringExercise = useCallback(async () => {
    if (!id || !dto) return;

    const api = new MonitoringExerciseResourceApi();
    await api.updateMonitoringExercise({
      id,
      monitoringExerciseRequestDto: dto,
    });
    done();
  }, [id, dto, done]);

  return (
    <React.Fragment>
      <PrimaryButton disabled={!dto} onClick={updateMonitoringExercise}>
        Update
      </PrimaryButton>
      <DefaultButton onClick={done}>Cancel</DefaultButton>
    </React.Fragment>
  );
};

export default UpdateMonitoringExercise;
