import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Main from "./Main";
import MonitoringExercises from "./MonitoringExercises";
import CreateMonitoringExercise from "./CreateMonitoringExercise";
import UpdateMonitoringExercise from "./UpdateMonitoringExercise";

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />}>
        <Route index element={<Navigate to="monitoring" />} />
        <Route path="monitoring">
          <Route index element={<MonitoringExercises />} />
          <Route path="create" element={<CreateMonitoringExercise />} />
          <Route path=":id" element={<UpdateMonitoringExercise />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
