/* tslint:disable */
/* eslint-disable */
/**
 * compliance-api API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MiningOperationDto
 */
export interface MiningOperationDto {
    /**
     * 
     * @type {string}
     * @memberof MiningOperationDto
     */
    miningDistrict: string;
    /**
     * 
     * @type {string}
     * @memberof MiningOperationDto
     */
    owner: string;
    /**
     * 
     * @type {string}
     * @memberof MiningOperationDto
     */
    sdNumber: string;
    /**
     * 
     * @type {string}
     * @memberof MiningOperationDto
     */
    area?: string;
    /**
     * 
     * @type {string}
     * @memberof MiningOperationDto
     */
    coordinates?: string;
    /**
     * 
     * @type {string}
     * @memberof MiningOperationDto
     */
    pointWkt?: string;
    /**
     * 
     * @type {string}
     * @memberof MiningOperationDto
     */
    dredgeType?: string;
    /**
     * 
     * @type {string}
     * @memberof MiningOperationDto
     */
    breach?: string;
    /**
     * 
     * @type {string}
     * @memberof MiningOperationDto
     */
    mercuryStorage?: string;
    /**
     * 
     * @type {string}
     * @memberof MiningOperationDto
     */
    operatingPermission?: string;
    /**
     * 
     * @type {string}
     * @memberof MiningOperationDto
     */
    documentsSeen?: string;
    /**
     * 
     * @type {string}
     * @memberof MiningOperationDto
     */
    actions?: string;
    /**
     * 
     * @type {string}
     * @memberof MiningOperationDto
     */
    observations?: string;
    /**
     * 
     * @type {string}
     * @memberof MiningOperationDto
     */
    recommendations?: string;
}

export function MiningOperationDtoFromJSON(json: any): MiningOperationDto {
    return MiningOperationDtoFromJSONTyped(json, false);
}

export function MiningOperationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MiningOperationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'miningDistrict': json['miningDistrict'],
        'owner': json['owner'],
        'sdNumber': json['sdNumber'],
        'area': !exists(json, 'area') ? undefined : json['area'],
        'coordinates': !exists(json, 'coordinates') ? undefined : json['coordinates'],
        'pointWkt': !exists(json, 'pointWkt') ? undefined : json['pointWkt'],
        'dredgeType': !exists(json, 'dredgeType') ? undefined : json['dredgeType'],
        'breach': !exists(json, 'breach') ? undefined : json['breach'],
        'mercuryStorage': !exists(json, 'mercuryStorage') ? undefined : json['mercuryStorage'],
        'operatingPermission': !exists(json, 'operatingPermission') ? undefined : json['operatingPermission'],
        'documentsSeen': !exists(json, 'documentsSeen') ? undefined : json['documentsSeen'],
        'actions': !exists(json, 'actions') ? undefined : json['actions'],
        'observations': !exists(json, 'observations') ? undefined : json['observations'],
        'recommendations': !exists(json, 'recommendations') ? undefined : json['recommendations'],
    };
}

export function MiningOperationDtoToJSON(value?: MiningOperationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'miningDistrict': value.miningDistrict,
        'owner': value.owner,
        'sdNumber': value.sdNumber,
        'area': value.area,
        'coordinates': value.coordinates,
        'pointWkt': value.pointWkt,
        'dredgeType': value.dredgeType,
        'breach': value.breach,
        'mercuryStorage': value.mercuryStorage,
        'operatingPermission': value.operatingPermission,
        'documentsSeen': value.documentsSeen,
        'actions': value.actions,
        'observations': value.observations,
        'recommendations': value.recommendations,
    };
}

