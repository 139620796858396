import React, { createContext, useContext } from "react";

import { keycloak } from "./keycloak";

const KeycloakContext = createContext({
  keycloak,
});

export const KeycloakProvider: React.FC = ({ children }) => {
  return keycloak.authenticated ? (
    <KeycloakContext.Provider value={{ keycloak }}>
      {children}
    </KeycloakContext.Provider>
  ) : null;
};

export const useKeycloak = () => {
  const { keycloak } = useContext(KeycloakContext);
  return keycloak;
};
