/* tslint:disable */
/* eslint-disable */
/**
 * compliance-api API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentDto
 */
export interface DocumentDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    size: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    file: string;
}

export function DocumentDtoFromJSON(json: any): DocumentDto {
    return DocumentDtoFromJSONTyped(json, false);
}

export function DocumentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'size': json['size'],
        'file': json['file'],
    };
}

export function DocumentDtoToJSON(value?: DocumentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'size': value.size,
        'file': value.file,
    };
}

