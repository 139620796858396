import { createTheme } from "@fluentui/react";

export const theme = createTheme({
  palette: {
    themePrimary: "#4fa847",
    themeLighterAlt: "#f6fcf6",
    themeLighter: "#ddf1db",
    themeLight: "#c0e5bd",
    themeTertiary: "#8acb84",
    themeSecondary: "#5fb357",
    themeDarkAlt: "#479740",
    themeDark: "#3c8036",
    themeDarker: "#2c5e28",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralPrimaryAlt: "#3b3a39",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
});
