import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CommandBar,
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  useTheme,
} from "@fluentui/react";
import { Link } from "react-router-dom";
import { Container } from "@mui/system";
import { format } from "date-fns";
import { MonitoringExerciseDto, MonitoringExerciseResourceApi } from "../api";

const MonitoringExercises: React.FC = () => {
  const [monitoringExercises, setMonitoringExercises] =
    useState<MonitoringExerciseDto[]>();
  const [isFetching, setIsFetching] = useState(false);
  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);

  const theme = useTheme();

  const fetchMonitoringExercises = useCallback(async () => {
    const api = new MonitoringExerciseResourceApi();

    setIsFetching(true);
    const monitoringExercises = await api.listMonitoringExercises();
    setMonitoringExercises(monitoringExercises);
    setIsFetching(false);
  }, []);

  const commandBarItems = useMemo(() => {
    const commandBarItems: ICommandBarItemProps[] = [
      {
        key: "refresh",
        text: "Refresh",
        iconProps: { iconName: "Refresh" },
        onClick: fetchMonitoringExercises as VoidFunction,
      },
      {
        key: "create",
        text: "Create",
        iconProps: { iconName: "Add" },
        commandBarButtonAs: ({ defaultRender: CommandBarItem, ...props }) =>
          CommandBarItem ? (
            <Link to="create">
              <CommandBarItem {...props} />
            </Link>
          ) : null,
      },
    ];

    if (selectedIndices.length === 1 && monitoringExercises) {
      const selectedMonitoringExercise =
        monitoringExercises[selectedIndices[0]];

      commandBarItems.push(
        {
          key: "edit",
          text: "Edit/View",
          iconProps: { iconName: "Edit" },
          commandBarButtonAs: ({ defaultRender: CommandBarItem, ...props }) =>
            CommandBarItem ? (
              <Link to={selectedMonitoringExercise.id as string}>
                <CommandBarItem {...props} />
              </Link>
            ) : null,
        },
        {
          key: "delete",
          text: "Delete",
          iconProps: { iconName: "Delete" },
          buttonStyles: {
            icon: {
              color: theme.palette.redDark,
            },
            iconHovered: {
              color: theme.palette.redDark,
            },
          },
          onClick: () => {
            async function deleteMonitoringExercise() {
              const monitoringExerciseApi = new MonitoringExerciseResourceApi();
              await monitoringExerciseApi.deleteMonitoringExercise({
                id: selectedMonitoringExercise.id as string,
              });
              fetchMonitoringExercises();
            }

            deleteMonitoringExercise();
          },
        }
      );
    }

    return commandBarItems;
  }, [fetchMonitoringExercises, selectedIndices, monitoringExercises, theme]);

  const detailsListColumns: IColumn[] = useMemo(
    () => [
      {
        key: "location",
        name: "Location",
        fieldName: "location",
        minWidth: 100,
        maxWidth: 240,
      },
      {
        key: "date",
        name: "Date",
        fieldName: "date",
        minWidth: 140,
        maxWidth: 140,
      },
      {
        key: "miningOperations",
        name: "Mining Operations",
        fieldName: "miningOperationsCount",
        minWidth: 140,
        maxWidth: 140,
      },
      {
        key: "createdAt",
        name: "Created at",
        fieldName: "createdAt",
        minWidth: 140,
        maxWidth: 140,
      },
      {
        key: "updatedAt",
        name: "Updated at",
        fieldName: "updatedAt",
        minWidth: 140,
        maxWidth: 140,
      },
    ],
    []
  );

  const detailsListItems = useMemo(
    () =>
      monitoringExercises
        ? monitoringExercises.map(
            ({
              date,
              miningOperations,
              createdAt,
              updatedAt,
              ...exercise
            }) => ({
              ...exercise,
              date: format(date, "PP"),
              miningOperationsCount: miningOperations.length,
              createdAt: format(createdAt, "PPp"),
              updatedAt: format(updatedAt, "PPp"),
            })
          )
        : [],
    [monitoringExercises]
  );

  const selection = useMemo(
    () =>
      new Selection({
        selectionMode: SelectionMode.single,
        onSelectionChanged: () => {
          const selectedIndices = selection.getSelectedIndices();
          setSelectedIndices([...selectedIndices]);
        },
      }),
    []
  );

  useEffect(() => {
    fetchMonitoringExercises();
  }, [fetchMonitoringExercises]);

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <CommandBar
        items={commandBarItems}
        styles={{
          root: {
            padding: 0,
          },
        }}
      />
      <ShimmeredDetailsList
        columns={detailsListColumns}
        items={detailsListItems}
        enableShimmer={isFetching}
        layoutMode={DetailsListLayoutMode.justified}
        selection={selection}
      />
    </Container>
  );
};

export default MonitoringExercises;
