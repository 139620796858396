/* tslint:disable */
/* eslint-disable */
/**
 * compliance-api API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActivityDto
 */
export interface ActivityDto {
    /**
     * 
     * @type {string}
     * @memberof ActivityDto
     */
    activity: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityDto
     */
    observation: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityDto
     */
    comments?: string;
}

export function ActivityDtoFromJSON(json: any): ActivityDto {
    return ActivityDtoFromJSONTyped(json, false);
}

export function ActivityDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activity': json['activity'],
        'observation': json['observation'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
    };
}

export function ActivityDtoToJSON(value?: ActivityDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activity': value.activity,
        'observation': value.observation,
        'comments': value.comments,
    };
}

