import React from "react";
import ReactDOM from "react-dom";
import { mergeStyles } from "@fluentui/react";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import "office-ui-fabric-core/dist/css/fabric.css";
import "ol/ol.css";

import { keycloak } from "./keycloak";
import Root from "./Root";
import reportWebVitals from "./reportWebVitals";

// Inject some global styles
mergeStyles({
  ":global(body,html,#root)": {
    margin: 0,
    padding: 0,
    height: "100vh",
  },
});

initializeIcons();

keycloak.init({ onLoad: "login-required" }).then((auth) => {
  if (!auth) window.location.reload();
  else {
    ReactDOM.render(
      <React.StrictMode>
        <Root />
      </React.StrictMode>,
      document.getElementById("root")
    );
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
