/* tslint:disable */
/* eslint-disable */
/**
 * compliance-api API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DocumentDto,
    DocumentDtoFromJSON,
    DocumentDtoToJSON,
    MonitoringExerciseDto,
    MonitoringExerciseDtoFromJSON,
    MonitoringExerciseDtoToJSON,
    MonitoringExerciseRequestDto,
    MonitoringExerciseRequestDtoFromJSON,
    MonitoringExerciseRequestDtoToJSON,
} from '../models';

export interface CreateMonitoringExerciseRequest {
    monitoringExerciseRequestDto?: MonitoringExerciseRequestDto;
}

export interface DeleteMonitoringExerciseRequest {
    id: string;
}

export interface FindMonitoringExerciseRequest {
    id: string;
}

export interface ListDocumentsRequest {
    id: string;
}

export interface ListMonitoringExercisesRequest {
    index?: number;
    size?: number;
}

export interface UpdateMonitoringExerciseRequest {
    id: string;
    monitoringExerciseRequestDto?: MonitoringExerciseRequestDto;
}

/**
 * 
 */
export class MonitoringExerciseResourceApi extends runtime.BaseAPI {

    /**
     */
    async createMonitoringExerciseRaw(requestParameters: CreateMonitoringExerciseRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/monitoring-exercise`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoringExerciseRequestDtoToJSON(requestParameters.monitoringExerciseRequestDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createMonitoringExercise(requestParameters: CreateMonitoringExerciseRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.createMonitoringExerciseRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteMonitoringExerciseRaw(requestParameters: DeleteMonitoringExerciseRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteMonitoringExercise.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/monitoring-exercise/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteMonitoringExercise(requestParameters: DeleteMonitoringExerciseRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.deleteMonitoringExerciseRaw(requestParameters, initOverrides);
    }

    /**
     */
    async findMonitoringExerciseRaw(requestParameters: FindMonitoringExerciseRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MonitoringExerciseDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findMonitoringExercise.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/monitoring-exercise/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoringExerciseDtoFromJSON(jsonValue));
    }

    /**
     */
    async findMonitoringExercise(requestParameters: FindMonitoringExerciseRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MonitoringExerciseDto> {
        const response = await this.findMonitoringExerciseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listDocumentsRaw(requestParameters: ListDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<DocumentDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling listDocuments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/monitoring-exercise/{id}/documents`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentDtoFromJSON));
    }

    /**
     */
    async listDocuments(requestParameters: ListDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<DocumentDto>> {
        const response = await this.listDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listMonitoringExercisesRaw(requestParameters: ListMonitoringExercisesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<MonitoringExerciseDto>>> {
        const queryParameters: any = {};

        if (requestParameters.index !== undefined) {
            queryParameters['index'] = requestParameters.index;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/monitoring-exercise`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonitoringExerciseDtoFromJSON));
    }

    /**
     */
    async listMonitoringExercises(requestParameters: ListMonitoringExercisesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<MonitoringExerciseDto>> {
        const response = await this.listMonitoringExercisesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateMonitoringExerciseRaw(requestParameters: UpdateMonitoringExerciseRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMonitoringExercise.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/monitoring-exercise/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoringExerciseRequestDtoToJSON(requestParameters.monitoringExerciseRequestDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateMonitoringExercise(requestParameters: UpdateMonitoringExerciseRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.updateMonitoringExerciseRaw(requestParameters, initOverrides);
    }

}
