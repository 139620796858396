import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@fluentui/react";
import { KeycloakProvider } from "./KeycloakContext";

import { theme } from "./theme";
import App from "./components/App";

const Root: React.FC = () => {
  return (
    <KeycloakProvider>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </KeycloakProvider>
  );
};

export default Root;
