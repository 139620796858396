import React, { createContext, useContext, useMemo, useState } from "react";
import { Container } from "@mui/system";
import { Label, Stack, TextField, useTheme } from "@fluentui/react";
import GeometryType from "ol/geom/GeometryType";

import { MiningOperationDto } from "../api";
import DrawFeature from "./DrawFeature";

type Form = Partial<MiningOperationDto>;

type Context = {
  miningOperationForm: Form;
  setMiningOperationForm: (form: Form) => void;
  miningOperationDto: MiningOperationDto | undefined;
};

export const MiningOperationFormContext = createContext<Context>({
  miningOperationForm: {},
  setMiningOperationForm: (_) => {},
  miningOperationDto: undefined,
});

type MiningOperationFormProviderProps = {
  miningOperationForm?: Form;
};

export const MiningOperationFormProvider: React.FC<
  MiningOperationFormProviderProps
> = (props) => {
  const [miningOperationForm, setMiningOperationForm] = useState<Form>(
    props.miningOperationForm || {}
  );

  const miningOperationDto: MiningOperationDto | undefined = useMemo(() => {
    const { miningDistrict, owner, sdNumber } = miningOperationForm;

    return miningDistrict && owner && sdNumber
      ? { ...miningOperationForm, miningDistrict, owner, sdNumber }
      : undefined;
  }, [miningOperationForm]);

  return (
    <MiningOperationFormContext.Provider
      value={{
        miningOperationForm,
        setMiningOperationForm,
        miningOperationDto,
      }}
    >
      {props.children}
    </MiningOperationFormContext.Provider>
  );
};

export const MiningOperationFormContainer: React.FC = ({ children }) => {
  return (
    <Container disableGutters maxWidth="md" sx={{ minWidth: 640, p: 2 }}>
      {children}
    </Container>
  );
};

const MiningOperationForm: React.FC = () => {
  const { miningOperationForm, setMiningOperationForm } = useContext(
    MiningOperationFormContext
  );
  const theme = useTheme();

  return (
    <Stack
      className="ms-Grid"
      dir="ltr"
      styles={{ root: { marginTop: theme.spacing.s1 } }}
    >
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12 ms-md6">
          <TextField
            label="Mining district"
            value={miningOperationForm.miningDistrict || ""}
            onChange={(_, miningDistrict) =>
              setMiningOperationForm({
                ...miningOperationForm,
                miningDistrict: miningDistrict || undefined,
              })
            }
          />
        </div>

        <div className="ms-Grid-col ms-sm12 ms-md6">
          <TextField
            label="Owner"
            value={miningOperationForm.owner || ""}
            onChange={(_, owner) =>
              setMiningOperationForm({
                ...miningOperationForm,
                owner: owner || undefined,
              })
            }
          />
        </div>
      </div>

      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12 ms-md6">
          <TextField
            label="SD number"
            value={miningOperationForm.sdNumber || ""}
            onChange={(_, sdNumber) =>
              setMiningOperationForm({
                ...miningOperationForm,
                sdNumber: sdNumber || undefined,
              })
            }
          />
        </div>

        <div className="ms-Grid-col ms-sm12 ms-md6">
          <TextField
            label="Dredge type"
            value={miningOperationForm.dredgeType || ""}
            onChange={(_, dredgeType) =>
              setMiningOperationForm({
                ...miningOperationForm,
                dredgeType: dredgeType || undefined,
              })
            }
          />
        </div>
      </div>

      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12 ms-md6">
          <TextField
            label="Operating permission"
            value={miningOperationForm.operatingPermission || ""}
            onChange={(_, operatingPermission) =>
              setMiningOperationForm({
                ...miningOperationForm,
                operatingPermission: operatingPermission || undefined,
              })
            }
          />
        </div>
      </div>

      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12 ms-md6">
          <TextField
            label="Area"
            value={miningOperationForm.area || ""}
            onChange={(_, area) =>
              setMiningOperationForm({
                ...miningOperationForm,
                area: area || undefined,
              })
            }
          />
        </div>

        <div className="ms-Grid-col ms-sm12 ms-md6">
          <TextField
            label="Coordinates"
            value={miningOperationForm.coordinates || ""}
            onChange={(_, coordinates) =>
              setMiningOperationForm({
                ...miningOperationForm,
                coordinates: coordinates || undefined,
              })
            }
          />
        </div>
      </div>

      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12">
          <Label>Location</Label>
          <DrawFeature
            type={GeometryType.POINT}
            height={420}
            featureWkt={miningOperationForm.pointWkt}
            onDrawEnd={(pointWkt) =>
              setMiningOperationForm({ ...miningOperationForm, pointWkt })
            }
          />
        </div>
      </div>

      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12">
          <TextField
            multiline
            label="Mercury storage"
            value={miningOperationForm.mercuryStorage || ""}
            onChange={(_, mercuryStorage) =>
              setMiningOperationForm({
                ...miningOperationForm,
                mercuryStorage: mercuryStorage || undefined,
              })
            }
          />
        </div>
      </div>

      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12">
          <TextField
            multiline
            label="Documents seen"
            value={miningOperationForm.documentsSeen || ""}
            onChange={(_, documentsSeen) =>
              setMiningOperationForm({
                ...miningOperationForm,
                documentsSeen: documentsSeen || undefined,
              })
            }
          />
        </div>
      </div>

      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12">
          <TextField
            multiline
            label="Actions"
            value={miningOperationForm.actions || ""}
            onChange={(_, actions) =>
              setMiningOperationForm({
                ...miningOperationForm,
                actions: actions || undefined,
              })
            }
          />
        </div>
      </div>

      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12">
          <TextField
            multiline
            label="Observations"
            value={miningOperationForm.observations || ""}
            onChange={(_, observations) =>
              setMiningOperationForm({
                ...miningOperationForm,
                observations: observations || undefined,
              })
            }
          />
        </div>
      </div>

      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12">
          <TextField
            multiline
            label="Recommendations"
            value={miningOperationForm.recommendations || ""}
            onChange={(_, recommendations) =>
              setMiningOperationForm({
                ...miningOperationForm,
                recommendations: recommendations || undefined,
              })
            }
          />
        </div>
      </div>
    </Stack>
  );
};

export default MiningOperationForm;
