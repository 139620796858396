import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { Container } from "@mui/system";
import {
  ActionButton,
  DatePicker,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  FontWeights,
  IColumn,
  Label,
  Modal,
  PrimaryButton,
  Selection,
  SelectionMode,
  Stack,
  Text,
  TextField,
  useTheme,
} from "@fluentui/react";
import GeometryType from "ol/geom/GeometryType";
import prettyBytes from "pretty-bytes";
import { saveAs } from "file-saver";

import {
  ActivityDto,
  BusinessesDto,
  DocumentDto,
  FacilityDto,
  InfluentialPersonDto,
  MiningOperationDto,
  MonitoringExerciseRequestDto,
  PopulationDto,
  TeamMemberDto,
} from "../api";
import DrawFeature from "./DrawFeature";
import { Navigate } from "react-router-dom";
import MiningOperationForm, {
  MiningOperationFormContainer,
  MiningOperationFormContext,
  MiningOperationFormProvider,
} from "./MiningOperationForm";

type Form = Partial<MonitoringExerciseRequestDto>;

type Context = {
  form: Form;
  setForm: (form: Form) => void;
  dto: MonitoringExerciseRequestDto | undefined;
  done: VoidFunction;
};

export const MonitoringExerciseFormContext = createContext<Context>({
  form: {},
  setForm: (_) => {},
  dto: undefined,
  done: () => {},
});

export const MonitoringExerciseFormProvider: React.FC = ({ children }) => {
  const [form, setForm] = useState<Form>({});
  const [isDone, done] = useReducer(() => true, false);

  const dto: MonitoringExerciseRequestDto | undefined = useMemo(() => {
    const { location, date, ...dto } = form;

    return location && date
      ? {
          documents: dto.documents || [],
          team: dto.team || [],
          location,
          date,
          population: dto.population || [],
          facilities: dto.facilities || [],
          activities: dto.activities || [],
          businesses: dto.businesses || [],
          influentialPersons: dto.influentialPersons || [],
          miningOperations: dto.miningOperations || [],
          ...dto,
        }
      : undefined;
  }, [form]);

  if (isDone) return <Navigate to="/monitoring" />;

  return (
    <MonitoringExerciseFormContext.Provider
      value={{ form, setForm, dto, done }}
    >
      <Container maxWidth="lg" sx={{ py: 6 }}>
        {children}
      </Container>
    </MonitoringExerciseFormContext.Provider>
  );
};

const MonitoringExerciseForm: React.FC = () => {
  const theme = useTheme();

  return (
    <Stack
      className="ms-Grid"
      dir="ltr"
      tokens={{ childrenGap: 50 }}
      styles={{ root: { marginTop: theme.spacing.m } }}
    >
      {/* Documents*/}
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12">
          <Text
            variant="large"
            styles={{ root: { fontWeight: FontWeights.semibold } }}
          >
            Documents
          </Text>
          <Documents />
        </div>
      </div>

      {/* Team */}
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12">
          <Text
            variant="large"
            styles={{ root: { fontWeight: FontWeights.semibold } }}
          >
            Team
          </Text>
          <Team />
        </div>
      </div>

      {/* Intelligence */}
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12">
          <Text
            variant="large"
            styles={{
              root: {
                fontWeight: FontWeights.semibold,
              },
            }}
          >
            Intelligence
          </Text>
          <Intelligence />
        </div>
      </div>

      {/* Field inspection */}
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12">
          <Text
            variant="large"
            styles={{
              root: {
                fontWeight: FontWeights.semibold,
              },
            }}
          >
            Field inspection
          </Text>
          <FieldInspection />
        </div>
      </div>
    </Stack>
  );
};

export const MonitoringExerciseFormActionsContainer: React.FC = ({
  children,
}) => {
  const theme = useTheme();

  return (
    <Stack
      className="ms-Grid"
      dir="ltr"
      styles={{ root: { marginTop: theme.spacing.m } }}
    >
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12">
          <Stack
            horizontal
            horizontalAlign="end"
            tokens={{ childrenGap: 20 }}
            styles={{ root: { marginTop: theme.spacing.m } }}
          >
            {children}
          </Stack>
        </div>
      </div>
    </Stack>
  );
};

const Documents: React.FC = () => {
  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);
  const input = useRef<HTMLInputElement>(null);

  const theme = useTheme();
  const { form, setForm } = useContext(MonitoringExerciseFormContext);

  const columns: IColumn[] = useMemo(
    () => [
      {
        key: "name",
        name: "Name",
        fieldName: "name",
        minWidth: 100,
      },
      {
        key: "size",
        name: "Size",
        fieldName: "size",
        minWidth: 100,
      },
    ],
    []
  );

  const selection = useMemo(
    () =>
      new Selection({
        selectionMode: SelectionMode.multiple,
        onSelectionChanged: () => {
          const selectedIndices = selection.getSelectedIndices();
          setSelectedIndices([...selectedIndices]);
        },
      }),
    []
  );

  const addDocument = useCallback(
    (document: DocumentDto) =>
      setForm({
        ...form,
        documents: form.documents
          ? form.documents.concat(document)
          : [document],
      }),
    [form, setForm]
  );

  const removeSelected = useCallback(
    () =>
      setForm({
        ...form,
        documents: form.documents?.filter(
          (_, i) => !selectedIndices.includes(i)
        ),
      }),
    [form, setForm, selectedIndices]
  );

  const downloadSelected = useCallback(
    () =>
      form.documents
        ?.filter((_, i) => selectedIndices.includes(i))
        .forEach(({ file, name }) => saveAs(file, name)),
    [selectedIndices, form.documents]
  );

  const uploadHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) return;

      const fileReader = new FileReader();
      const fileUpload = event.target.files[0];

      fileReader.onload = () => {
        const { name, size } = fileUpload;
        const file = String(fileReader.result);
        addDocument({ name, size: prettyBytes(size), file });
      };

      fileReader.readAsDataURL(fileUpload);
    },
    [addDocument]
  );

  return (
    <React.Fragment>
      <input
        ref={input}
        type="file"
        accept=".pdf"
        style={{ display: "none" }}
        onChange={uploadHandler}
      />

      <Stack horizontal>
        <ActionButton onClick={() => input.current?.click()}>Add</ActionButton>
        <ActionButton
          styles={{ rootHovered: { color: theme.palette.redDark } }}
          disabled={selectedIndices.length === 0}
          onClick={removeSelected}
        >
          Delete
        </ActionButton>
        <ActionButton
          disabled={selectedIndices.length === 0}
          onClick={downloadSelected}
        >
          Download
        </ActionButton>
      </Stack>

      <DetailsList
        columns={columns}
        items={form.documents || []}
        selection={selection}
        layoutMode={DetailsListLayoutMode.justified}
        styles={{
          headerWrapper: {
            "& > div": {
              paddingTop: theme.spacing.s2,
            },
          },
        }}
      />
    </React.Fragment>
  );
};

const Team: React.FC = () => {
  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);
  const [showAddModal, setShowAddModal] = useState(false);

  const theme = useTheme();
  const { form, setForm } = useContext(MonitoringExerciseFormContext);

  const columns: IColumn[] = useMemo(
    () => [
      {
        key: "fullName",
        name: "Full name",
        fieldName: "fullName",
        minWidth: 100,
      },
      {
        key: "designation",
        name: "Designation",
        fieldName: "designation",
        minWidth: 100,
      },
    ],
    []
  );

  const selection = useMemo(
    () =>
      new Selection({
        selectionMode: SelectionMode.multiple,
        onSelectionChanged: () => {
          const selectedIndices = selection.getSelectedIndices();
          setSelectedIndices([...selectedIndices]);
        },
      }),
    []
  );

  const removeSelected = useCallback(
    () =>
      setForm({
        ...form,
        team: form.team?.filter((_, i) => !selectedIndices.includes(i)),
      }),
    [form, setForm, selectedIndices]
  );

  return (
    <React.Fragment>
      <Stack horizontal>
        <ActionButton onClick={() => setShowAddModal(true)}>Add</ActionButton>
        <ActionButton
          disabled={!selectedIndices.length}
          onClick={removeSelected}
          styles={{ rootHovered: { color: theme.palette.redDark } }}
        >
          Delete
        </ActionButton>
      </Stack>

      <DetailsList
        columns={columns}
        items={form.team || []}
        selection={selection}
        layoutMode={DetailsListLayoutMode.justified}
        styles={{
          headerWrapper: {
            "& > div": {
              paddingTop: theme.spacing.s2,
            },
          },
        }}
      />

      <Modal isOpen={showAddModal}>
        {showAddModal && (
          <TeamMemberCreate closeModal={() => setShowAddModal(false)} />
        )}
      </Modal>
    </React.Fragment>
  );
};

type ModalProps = {
  closeModal: VoidFunction;
};

type TeamMemberForm = Partial<TeamMemberDto>;

const TeamMemberCreate: React.FC<ModalProps> = ({ closeModal }) => {
  const [teamMember, setTeamMember] = useState<TeamMemberForm>({});

  const { form, setForm } = useContext(MonitoringExerciseFormContext);
  const theme = useTheme();

  const teamMemberDto: TeamMemberDto | undefined = useMemo(() => {
    const { fullName, designation } = teamMember;
    return fullName && designation ? { fullName, designation } : undefined;
  }, [teamMember]);

  const addTeamMember = useCallback(() => {
    if (!teamMemberDto) return;

    setForm({
      ...form,
      team: form.team ? form.team.concat(teamMemberDto) : [teamMemberDto],
    });

    closeModal();
  }, [form, setForm, teamMemberDto, closeModal]);

  return (
    <Container disableGutters maxWidth="xs" sx={{ minWidth: "444px", p: 2 }}>
      <Text
        variant="large"
        styles={{
          root: {
            fontWeight: FontWeights.semibold,
          },
        }}
      >
        Add team member
      </Text>

      {/* Form */}
      <Stack
        className="ms-Grid"
        dir="ltr"
        styles={{ root: { marginTop: theme.spacing.s1 } }}
      >
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <TextField
              label="Full name"
              value={teamMember.fullName || ""}
              onChange={(_, fullName) =>
                setTeamMember({
                  ...teamMember,
                  fullName: fullName || undefined,
                })
              }
            />
          </div>
        </div>

        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <TextField
              label="Designation"
              value={teamMember.designation || ""}
              onChange={(_, designation) =>
                setTeamMember({
                  ...teamMember,
                  designation: designation || undefined,
                })
              }
            />
          </div>
        </div>
      </Stack>

      {/* Actions */}
      <Stack
        className="ms-Grid"
        dir="ltr"
        styles={{ root: { marginTop: theme.spacing.m } }}
      >
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <Stack
              horizontal
              horizontalAlign="end"
              tokens={{ childrenGap: 10 }}
            >
              <PrimaryButton disabled={!teamMemberDto} onClick={addTeamMember}>
                Add
              </PrimaryButton>
              <DefaultButton onClick={closeModal}>Cancel</DefaultButton>
            </Stack>
          </div>
        </div>
      </Stack>
    </Container>
  );
};

const Intelligence: React.FC = () => {
  const { form, setForm } = useContext(MonitoringExerciseFormContext);

  return (
    <Stack className="ms-Grid" dir="ltr" tokens={{ childrenGap: 30 }}>
      {/* Location & Date */}
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12 ms-lg8">
          <TextField
            label="Location"
            value={form.location || ""}
            onChange={(_, location) =>
              setForm({ ...form, location: location || undefined })
            }
          />
        </div>

        <div className="ms-Grid-col ms-sm12 ms-lg4">
          <DatePicker
            label="Date"
            showMonthPickerAsOverlay
            value={form.date}
            onSelectDate={(date) =>
              setForm({ ...form, date: date ? date : undefined })
            }
          />
        </div>
      </div>

      {/* Map */}
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12">
          <Label>Map</Label>
          <DrawFeature
            type={GeometryType.POLYGON}
            height={420}
            featureWkt={form.polygonWkt}
            onDrawEnd={(polygonWkt) => setForm({ ...form, polygonWkt })}
          />
        </div>
      </div>

      {/* Pupulation */}
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12">
          <Label>Population</Label>
          <Population />
        </div>
      </div>

      {/* Activities */}
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12">
          <Label>Activities</Label>
          <Activities />
        </div>
      </div>

      {/* Facilities & Businesses */}
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12 ms-xl6">
          <Label>Facilities</Label>
          <Facilities />
        </div>

        <div className="ms-Grid-col ms-sm12 ms-xl6">
          <Label>Businesses</Label>
          <Businesses />
        </div>
      </div>

      {/* Influential persons */}
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12 ms-xl6">
          <Label>Influential persons</Label>
          <InfluentialPersons />
        </div>
      </div>

      {/* Feuds*/}
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12">
          <TextField
            multiline
            rows={3}
            label="Feuds"
            value={form.feuds || ""}
            onChange={(_, feuds) =>
              setForm({ ...form, feuds: feuds || undefined })
            }
          />
        </div>
      </div>

      {/* Other relevant information */}
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12">
          <TextField
            multiline
            rows={3}
            label="Other relevant information"
            value={form.otherInformation || ""}
            onChange={(_, otherInformation) =>
              setForm({
                ...form,
                otherInformation: otherInformation || undefined,
              })
            }
          />
        </div>
      </div>
    </Stack>
  );
};

const Population: React.FC = () => {
  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);
  const [showAddModal, setShowAddModal] = useState(false);

  const theme = useTheme();
  const { form, setForm } = useContext(MonitoringExerciseFormContext);

  const columns: IColumn[] = useMemo(
    () => [
      {
        key: "nationality",
        name: "Nationality",
        fieldName: "nationality",
        minWidth: 100,
      },
      {
        key: "females",
        name: "Females",
        fieldName: "females",
        minWidth: 60,
      },
      {
        key: "males",
        name: "Males",
        fieldName: "males",
        minWidth: 60,
      },
      {
        key: "children",
        name: "Children",
        fieldName: "children",
        minWidth: 60,
      },
      {
        key: "occupations",
        name: "General occupations",
        fieldName: "occupations",
        minWidth: 200,
      },
    ],
    []
  );

  const selection = useMemo(
    () =>
      new Selection({
        selectionMode: SelectionMode.multiple,
        onSelectionChanged: () => {
          const selectedIndices = selection.getSelectedIndices();
          setSelectedIndices([...selectedIndices]);
        },
      }),
    []
  );

  const removeSelected = useCallback(
    () =>
      setForm({
        ...form,
        population: form.population?.filter(
          (_, i) => !selectedIndices.includes(i)
        ),
      }),
    [form, setForm, selectedIndices]
  );

  return (
    <React.Fragment>
      <Stack horizontal>
        <ActionButton onClick={() => setShowAddModal(true)}>Add</ActionButton>
        <ActionButton
          disabled={!(selectedIndices.length > 0)}
          styles={{ rootHovered: { color: theme.palette.redDark } }}
          onClick={removeSelected}
        >
          Delete
        </ActionButton>
      </Stack>
      <DetailsList
        columns={columns}
        items={form.population || []}
        selection={selection}
        layoutMode={DetailsListLayoutMode.justified}
        styles={{
          headerWrapper: {
            "& > div": {
              paddingTop: theme.spacing.s2,
            },
          },
        }}
      />

      <Modal isOpen={showAddModal}>
        {showAddModal && (
          <PopulationCreate closeModal={() => setShowAddModal(false)} />
        )}
      </Modal>
    </React.Fragment>
  );
};

type PopulationForm = Partial<PopulationDto>;

const PopulationCreate: React.FC<ModalProps> = ({ closeModal }) => {
  const [population, setPopulation] = useState<PopulationForm>({});

  const { form, setForm } = useContext(MonitoringExerciseFormContext);
  const theme = useTheme();

  const populationDto: PopulationDto | undefined = useMemo(() => {
    const { nationality, females, males, children } = population;

    if (females && females < 0) return undefined;

    if (males && males < 0) return undefined;

    if (children && children < 0) return undefined;

    return nationality ? { ...population, nationality } : undefined;
  }, [population]);

  const addPopulation = useCallback(() => {
    if (!populationDto) return;

    setForm({
      ...form,
      population: form.population
        ? form.population.concat(populationDto)
        : [populationDto],
    });

    closeModal();
  }, [form, setForm, populationDto, closeModal]);

  return (
    <Container disableGutters maxWidth="xs" sx={{ minWidth: "444px", p: 2 }}>
      <Text
        variant="large"
        styles={{
          root: {
            fontWeight: FontWeights.semibold,
          },
        }}
      >
        Add population
      </Text>

      {/* Form */}
      <Stack
        className="ms-Grid"
        dir="ltr"
        styles={{ root: { marginTop: theme.spacing.s1 } }}
      >
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <TextField
              label="Nationality"
              value={population.nationality || ""}
              onChange={(_, nationality) =>
                setPopulation({
                  ...population,
                  nationality: nationality || undefined,
                })
              }
            />
          </div>
        </div>

        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm4">
            <TextField
              label="Females"
              type="number"
              value={
                population.females !== undefined
                  ? String(population.females)
                  : ""
              }
              onChange={(_, females) =>
                setPopulation({
                  ...population,
                  females: females !== undefined ? Number(females) : undefined,
                })
              }
            />
          </div>

          <div className="ms-Grid-col ms-sm4">
            <TextField
              label="Males"
              type="number"
              value={
                population.males !== undefined ? String(population.males) : ""
              }
              onChange={(_, males) =>
                setPopulation({
                  ...population,
                  males: males !== undefined ? Number(males) : undefined,
                })
              }
            />
          </div>

          <div className="ms-Grid-col ms-sm4">
            <TextField
              label="Children"
              type="number"
              value={
                population.children !== undefined
                  ? String(population.children)
                  : ""
              }
              onChange={(_, children) =>
                setPopulation({
                  ...population,
                  children:
                    children !== undefined ? Number(children) : undefined,
                })
              }
            />
          </div>
        </div>

        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <TextField
              multiline
              label="General occupations"
              value={population.occupations || ""}
              onChange={(_, occupations) =>
                setPopulation({
                  ...population,
                  occupations: occupations || undefined,
                })
              }
            />
          </div>
        </div>
      </Stack>

      {/* Actions */}
      <Stack
        className="ms-Grid"
        dir="ltr"
        styles={{ root: { marginTop: theme.spacing.m } }}
      >
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <Stack
              horizontal
              horizontalAlign="end"
              tokens={{ childrenGap: 10 }}
            >
              <PrimaryButton disabled={!populationDto} onClick={addPopulation}>
                Add
              </PrimaryButton>
              <DefaultButton onClick={closeModal}>Cancel</DefaultButton>
            </Stack>
          </div>
        </div>
      </Stack>
    </Container>
  );
};

const Activities: React.FC = () => {
  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);
  const [showAddModal, setShowAddModal] = useState(false);

  const theme = useTheme();
  const { form, setForm } = useContext(MonitoringExerciseFormContext);

  const columns: IColumn[] = useMemo(
    () => [
      {
        key: "activity",
        name: "Activity",
        fieldName: "activity",
        minWidth: 60,
      },
      {
        key: "observation",
        name: "Observation",
        fieldName: "observation",
        minWidth: 100,
      },
      {
        key: "comments",
        name: "Comments",
        fieldName: "comments",
        minWidth: 100,
      },
    ],
    []
  );

  const selection = useMemo(
    () =>
      new Selection({
        selectionMode: SelectionMode.multiple,
        onSelectionChanged: () => {
          const selectedIndices = selection.getSelectedIndices();
          setSelectedIndices([...selectedIndices]);
        },
      }),
    []
  );

  const removeSelected = useCallback(
    () =>
      setForm({
        ...form,
        activities: form.activities?.filter(
          (_, i) => !selectedIndices.includes(i)
        ),
      }),
    [form, setForm, selectedIndices]
  );

  return (
    <React.Fragment>
      <Stack horizontal>
        <ActionButton onClick={() => setShowAddModal(true)}>Add</ActionButton>
        <ActionButton
          disabled={!selectedIndices.length}
          onClick={removeSelected}
          styles={{ rootHovered: { color: theme.palette.redDark } }}
        >
          Delete
        </ActionButton>
      </Stack>
      <DetailsList
        columns={columns}
        items={form.activities || []}
        selection={selection}
        layoutMode={DetailsListLayoutMode.justified}
        styles={{
          headerWrapper: {
            "& > div": {
              paddingTop: theme.spacing.s2,
            },
          },
        }}
      />

      <Modal isOpen={showAddModal}>
        {showAddModal && (
          <ActivityCreate closeModal={() => setShowAddModal(false)} />
        )}
      </Modal>
    </React.Fragment>
  );
};

type ActivityForm = Partial<ActivityDto>;

const ActivityCreate: React.FC<ModalProps> = ({ closeModal }) => {
  const [activityForm, setActivityForm] = useState<ActivityForm>({});

  const { form, setForm } = useContext(MonitoringExerciseFormContext);
  const theme = useTheme();

  const activityDto: ActivityDto | undefined = useMemo(() => {
    const { activity, observation } = activityForm;

    return activity && observation
      ? { ...activityForm, activity, observation }
      : undefined;
  }, [activityForm]);

  const addActivity = useCallback(() => {
    if (!activityDto) return;

    setForm({
      ...form,
      activities: form.activities
        ? form.activities.concat(activityDto)
        : [activityDto],
    });

    closeModal();
  }, [form, setForm, activityDto, closeModal]);

  return (
    <Container disableGutters maxWidth="xs" sx={{ minWidth: "444px", p: 2 }}>
      <Text
        variant="large"
        styles={{
          root: {
            fontWeight: FontWeights.semibold,
          },
        }}
      >
        Add activity
      </Text>

      {/* Form */}
      <Stack
        className="ms-Grid"
        dir="ltr"
        styles={{ root: { marginTop: theme.spacing.s1 } }}
      >
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <TextField
              label="Activity"
              value={activityForm.activity || ""}
              onChange={(_, activity) =>
                setActivityForm({
                  ...activityForm,
                  activity: activity || undefined,
                })
              }
            />
          </div>
        </div>

        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <TextField
              label="Observation"
              value={activityForm.observation || ""}
              onChange={(_, observation) =>
                setActivityForm({
                  ...activityForm,
                  observation: observation || undefined,
                })
              }
            />
          </div>
        </div>

        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <TextField
              multiline
              label="Comments"
              value={activityForm.comments || ""}
              onChange={(_, comments) =>
                setActivityForm({
                  ...activityForm,
                  comments: comments || undefined,
                })
              }
            />
          </div>
        </div>
      </Stack>

      {/* Actions */}
      <Stack
        className="ms-Grid"
        dir="ltr"
        styles={{ root: { marginTop: theme.spacing.m } }}
      >
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <Stack
              horizontal
              horizontalAlign="end"
              tokens={{ childrenGap: 10 }}
            >
              <PrimaryButton disabled={!activityDto} onClick={addActivity}>
                Add
              </PrimaryButton>
              <DefaultButton onClick={closeModal}>Cancel</DefaultButton>
            </Stack>
          </div>
        </div>
      </Stack>
    </Container>
  );
};

const Facilities: React.FC = () => {
  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);
  const [showAddModal, setShowAddModal] = useState(false);

  const theme = useTheme();
  const { form, setForm } = useContext(MonitoringExerciseFormContext);

  const columns: IColumn[] = useMemo(
    () => [
      {
        key: "sector",
        name: "Sector",
        fieldName: "sector",
        minWidth: 60,
      },
      {
        key: "facilities",
        name: "Facilities",
        fieldName: "facilities",
        minWidth: 100,
      },
    ],
    []
  );

  const selection = useMemo(
    () =>
      new Selection({
        selectionMode: SelectionMode.multiple,
        onSelectionChanged: () => {
          const selectedIndices = selection.getSelectedIndices();
          setSelectedIndices([...selectedIndices]);
        },
      }),
    []
  );

  const removeSelected = useCallback(
    () =>
      setForm({
        ...form,
        facilities: form.facilities?.filter(
          (_, i) => !selectedIndices.includes(i)
        ),
      }),
    [form, setForm, selectedIndices]
  );

  return (
    <React.Fragment>
      <Stack horizontal>
        <ActionButton onClick={() => setShowAddModal(true)}>Add</ActionButton>
        <ActionButton
          disabled={!selectedIndices.length}
          onClick={removeSelected}
          styles={{ rootHovered: { color: theme.palette.redDark } }}
        >
          Delete
        </ActionButton>
      </Stack>
      <DetailsList
        columns={columns}
        items={form.facilities || []}
        selection={selection}
        layoutMode={DetailsListLayoutMode.justified}
        styles={{
          headerWrapper: {
            "& > div": {
              paddingTop: theme.spacing.s2,
            },
          },
        }}
      />

      <Modal isOpen={showAddModal}>
        {showAddModal && (
          <FacilityCreate closeModal={() => setShowAddModal(false)} />
        )}
      </Modal>
    </React.Fragment>
  );
};

type FacilityForm = Partial<FacilityDto>;

const FacilityCreate: React.FC<ModalProps> = ({ closeModal }) => {
  const [facilityForm, setFacilityForm] = useState<FacilityForm>({});

  const { form, setForm } = useContext(MonitoringExerciseFormContext);
  const theme = useTheme();

  const facilityDto: FacilityDto | undefined = useMemo(() => {
    const { sector, facilities } = facilityForm;

    return sector && facilities
      ? { ...facilityForm, sector, facilities }
      : undefined;
  }, [facilityForm]);

  const addFacility = useCallback(() => {
    if (!facilityDto) return;

    setForm({
      ...form,
      facilities: form.facilities
        ? form.facilities.concat(facilityDto)
        : [facilityDto],
    });

    closeModal();
  }, [form, setForm, facilityDto, closeModal]);

  return (
    <Container disableGutters maxWidth="xs" sx={{ minWidth: "444px", p: 2 }}>
      <Text
        variant="large"
        styles={{
          root: {
            fontWeight: FontWeights.semibold,
          },
        }}
      >
        Add facility
      </Text>

      {/* Form */}
      <Stack
        className="ms-Grid"
        dir="ltr"
        styles={{ root: { marginTop: theme.spacing.s1 } }}
      >
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <TextField
              label="Sector"
              value={facilityForm.sector || ""}
              onChange={(_, sector) =>
                setFacilityForm({
                  ...facilityForm,
                  sector: sector || undefined,
                })
              }
            />
          </div>
        </div>

        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <TextField
              multiline
              label="Facilities"
              value={facilityForm.facilities || ""}
              onChange={(_, facilities) =>
                setFacilityForm({
                  ...facilityForm,
                  facilities: facilities || undefined,
                })
              }
            />
          </div>
        </div>
      </Stack>

      {/* Actions */}
      <Stack
        className="ms-Grid"
        dir="ltr"
        styles={{ root: { marginTop: theme.spacing.m } }}
      >
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <Stack
              horizontal
              horizontalAlign="end"
              tokens={{ childrenGap: 10 }}
            >
              <PrimaryButton disabled={!facilityDto} onClick={addFacility}>
                Add
              </PrimaryButton>
              <DefaultButton onClick={closeModal}>Cancel</DefaultButton>
            </Stack>
          </div>
        </div>
      </Stack>
    </Container>
  );
};

const Businesses: React.FC = () => {
  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);
  const [showAddModal, setShowAddModal] = useState(false);

  const theme = useTheme();
  const { form, setForm } = useContext(MonitoringExerciseFormContext);

  const columns: IColumn[] = useMemo(
    () => [
      {
        key: "type",
        name: "Type",
        fieldName: "type",
        minWidth: 60,
      },
      {
        key: "operators",
        name: "Operators",
        fieldName: "operators",
        minWidth: 100,
      },
    ],
    []
  );

  const selection = useMemo(
    () =>
      new Selection({
        selectionMode: SelectionMode.multiple,
        onSelectionChanged: () => {
          const selectedIndices = selection.getSelectedIndices();
          setSelectedIndices([...selectedIndices]);
        },
      }),
    []
  );

  const removeSelected = useCallback(
    () =>
      setForm({
        ...form,
        businesses: form.businesses?.filter(
          (_, i) => !selectedIndices.includes(i)
        ),
      }),
    [form, setForm, selectedIndices]
  );

  return (
    <React.Fragment>
      <Stack horizontal>
        <ActionButton onClick={() => setShowAddModal(true)}>Add</ActionButton>
        <ActionButton
          disabled={!selectedIndices.length}
          onClick={removeSelected}
          styles={{ rootHovered: { color: theme.palette.redDark } }}
        >
          Delete
        </ActionButton>
      </Stack>
      <DetailsList
        columns={columns}
        items={form.businesses || []}
        selection={selection}
        layoutMode={DetailsListLayoutMode.justified}
        styles={{
          headerWrapper: {
            "& > div": {
              paddingTop: theme.spacing.s2,
            },
          },
        }}
      />

      <Modal isOpen={showAddModal}>
        {showAddModal && (
          <BusinessesCreate closeModal={() => setShowAddModal(false)} />
        )}
      </Modal>
    </React.Fragment>
  );
};

type BusinessesForm = Partial<BusinessesDto>;

const BusinessesCreate: React.FC<ModalProps> = ({ closeModal }) => {
  const [businessesForm, setBusinessesForm] = useState<BusinessesForm>({});

  const { form, setForm } = useContext(MonitoringExerciseFormContext);
  const theme = useTheme();

  const businesssesDto: BusinessesDto | undefined = useMemo(() => {
    const { type, operators } = businessesForm;

    return type && operators
      ? { ...businessesForm, type, operators }
      : undefined;
  }, [businessesForm]);

  const addBusinesses = useCallback(() => {
    if (!businesssesDto) return;

    setForm({
      ...form,
      businesses: form.businesses
        ? form.businesses.concat(businesssesDto)
        : [businesssesDto],
    });

    closeModal();
  }, [form, setForm, businesssesDto, closeModal]);

  return (
    <Container disableGutters maxWidth="xs" sx={{ minWidth: "444px", p: 2 }}>
      <Text
        variant="large"
        styles={{
          root: {
            fontWeight: FontWeights.semibold,
          },
        }}
      >
        Add businesses
      </Text>

      {/* Form */}
      <Stack
        className="ms-Grid"
        dir="ltr"
        styles={{ root: { marginTop: theme.spacing.s1 } }}
      >
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <TextField
              label="Type"
              value={businessesForm.type || ""}
              onChange={(_, type) =>
                setBusinessesForm({
                  ...businessesForm,
                  type: type || undefined,
                })
              }
            />
          </div>
        </div>

        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <TextField
              multiline
              label="Operators"
              value={businessesForm.operators || ""}
              onChange={(_, operators) =>
                setBusinessesForm({
                  ...businessesForm,
                  operators: operators || undefined,
                })
              }
            />
          </div>
        </div>
      </Stack>

      {/* Actions */}
      <Stack
        className="ms-Grid"
        dir="ltr"
        styles={{ root: { marginTop: theme.spacing.m } }}
      >
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <Stack
              horizontal
              horizontalAlign="end"
              tokens={{ childrenGap: 10 }}
            >
              <PrimaryButton disabled={!businesssesDto} onClick={addBusinesses}>
                Add
              </PrimaryButton>
              <DefaultButton onClick={closeModal}>Cancel</DefaultButton>
            </Stack>
          </div>
        </div>
      </Stack>
    </Container>
  );
};

const InfluentialPersons: React.FC = () => {
  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);
  const [showAddModal, setShowAddModal] = useState(false);

  const theme = useTheme();
  const { form, setForm } = useContext(MonitoringExerciseFormContext);

  const columns: IColumn[] = useMemo(
    () => [
      {
        key: "name",
        name: "Name",
        fieldName: "name",
        minWidth: 100,
      },
      {
        key: "role",
        name: "Role",
        fieldName: "role",
        minWidth: 60,
      },
    ],
    []
  );

  const selection = useMemo(
    () =>
      new Selection({
        selectionMode: SelectionMode.multiple,
        onSelectionChanged: () => {
          const selectedIndices = selection.getSelectedIndices();
          setSelectedIndices([...selectedIndices]);
        },
      }),
    []
  );

  const removeSelected = useCallback(
    () =>
      setForm({
        ...form,
        influentialPersons: form.influentialPersons?.filter(
          (_, i) => !selectedIndices.includes(i)
        ),
      }),
    [form, setForm, selectedIndices]
  );

  return (
    <React.Fragment>
      <Stack horizontal>
        <ActionButton onClick={() => setShowAddModal(true)}>Add</ActionButton>
        <ActionButton
          disabled={!selectedIndices.length}
          onClick={removeSelected}
          styles={{ rootHovered: { color: theme.palette.redDark } }}
        >
          Delete
        </ActionButton>
      </Stack>
      <DetailsList
        columns={columns}
        items={form.businesses || []}
        selection={selection}
        layoutMode={DetailsListLayoutMode.justified}
        styles={{
          headerWrapper: {
            "& > div": {
              paddingTop: theme.spacing.s2,
            },
          },
        }}
      />

      <Modal isOpen={showAddModal}>
        {showAddModal && (
          <InfluentialPersonCreate closeModal={() => setShowAddModal(false)} />
        )}
      </Modal>
    </React.Fragment>
  );
};

type InfluentialPersonForm = Partial<InfluentialPersonDto>;

const InfluentialPersonCreate: React.FC<ModalProps> = ({ closeModal }) => {
  const [influentialPersonForm, setInfluentialPersonForm] =
    useState<InfluentialPersonForm>({});

  const { form, setForm } = useContext(MonitoringExerciseFormContext);
  const theme = useTheme();

  const influentialPersonDto: InfluentialPersonDto | undefined = useMemo(() => {
    const { name } = influentialPersonForm;

    return name ? { ...influentialPersonForm, name } : undefined;
  }, [influentialPersonForm]);

  const addInfluentialPerson = useCallback(() => {
    if (!influentialPersonDto) return;

    setForm({
      ...form,
      influentialPersons: form.influentialPersons
        ? form.influentialPersons.concat(influentialPersonDto)
        : [influentialPersonDto],
    });

    closeModal();
  }, [form, setForm, influentialPersonDto, closeModal]);

  return (
    <Container disableGutters maxWidth="xs" sx={{ minWidth: "444px", p: 2 }}>
      <Text
        variant="large"
        styles={{
          root: {
            fontWeight: FontWeights.semibold,
          },
        }}
      >
        Add influential person
      </Text>

      {/* Form */}
      <Stack
        className="ms-Grid"
        dir="ltr"
        styles={{ root: { marginTop: theme.spacing.s1 } }}
      >
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <TextField
              label="Name"
              value={influentialPersonForm.name || ""}
              onChange={(_, name) =>
                setInfluentialPersonForm({
                  ...influentialPersonForm,
                  name: name || undefined,
                })
              }
            />
          </div>
        </div>

        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <TextField
              label="Role"
              value={influentialPersonForm.role || ""}
              onChange={(_, role) =>
                setInfluentialPersonForm({
                  ...influentialPersonForm,
                  role: role || undefined,
                })
              }
            />
          </div>
        </div>
      </Stack>

      {/* Actions */}
      <Stack
        className="ms-Grid"
        dir="ltr"
        styles={{ root: { marginTop: theme.spacing.m } }}
      >
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <Stack
              horizontal
              horizontalAlign="end"
              tokens={{ childrenGap: 10 }}
            >
              <PrimaryButton
                disabled={!influentialPersonDto}
                onClick={addInfluentialPerson}
              >
                Add
              </PrimaryButton>
              <DefaultButton onClick={closeModal}>Cancel</DefaultButton>
            </Stack>
          </div>
        </div>
      </Stack>
    </Container>
  );
};

const FieldInspection: React.FC = () => {
  return (
    <Stack className="ms-Grid" dir="ltr" tokens={{ childrenGap: 30 }}>
      {/* Pupulation */}
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12">
          <Label>Mining operations</Label>
          <MiningOperations />
        </div>
      </div>
    </Stack>
  );
};

const MiningOperations: React.FC = () => {
  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const { form, setForm } = useContext(MonitoringExerciseFormContext);
  const theme = useTheme();

  const columns: IColumn[] = useMemo(
    () => [
      {
        key: "miningDistrict",
        name: "Mining district",
        fieldName: "miningDistrict",
        minWidth: 60,
      },
      {
        key: "owner",
        name: "Owner",
        fieldName: "owner",
        minWidth: 80,
      },
      {
        key: "sdNumber",
        name: "SD number",
        fieldName: "sdNumber",
        minWidth: 80,
      },
      {
        key: "area",
        name: "Area",
        fieldName: "area",
        minWidth: 80,
      },
      {
        key: "dredgeType",
        name: "Dredge type",
        fieldName: "dredgeType",
        minWidth: 80,
      },
    ],
    []
  );

  const selection = useMemo(
    () =>
      new Selection({
        selectionMode: SelectionMode.multiple,
        onSelectionChanged: () => {
          const selectedIndices = selection.getSelectedIndices();
          setSelectedIndices([...selectedIndices]);
        },
      }),
    []
  );

  const openAddModal = useCallback(() => setShowAddModal(true), []);

  const closeAddModal = useCallback(() => setShowAddModal(false), []);

  const openEditModal = useCallback(() => setShowEditModal(true), []);

  const closeEditModal = useCallback(() => setShowEditModal(false), []);

  const removeSelected = useCallback(
    () =>
      setForm({
        ...form,
        miningOperations: form.miningOperations?.filter(
          (_, i) => !selectedIndices.includes(i)
        ),
      }),
    [form, setForm, selectedIndices]
  );

  const addMiningOperation = useCallback(
    (miningOperationDto: MiningOperationDto) => {
      const { miningOperations } = form;

      setForm({
        ...form,
        miningOperations: miningOperations
          ? miningOperations.concat(miningOperationDto)
          : [miningOperationDto],
      });

      closeAddModal();
    },
    [form, setForm, closeAddModal]
  );

  const singleSelectedMiningOperation = useMemo(() => {
    const { miningOperations } = form;

    return miningOperations && selectedIndices.length === 1
      ? miningOperations[selectedIndices[0]]
      : undefined;
  }, [form, selectedIndices]);

  const updateMiningOperation = useCallback(
    (miningOperationDto: MiningOperationDto) => {
      const { miningOperations } = form;

      setForm({
        ...form,
        miningOperations: miningOperations?.map((miningOperation) =>
          miningOperation === singleSelectedMiningOperation
            ? miningOperationDto
            : miningOperation
        ),
      });
      closeEditModal();
    },
    [form, setForm, singleSelectedMiningOperation, closeEditModal]
  );

  return (
    <React.Fragment>
      <Stack horizontal>
        <ActionButton onClick={openAddModal}>Add</ActionButton>
        <ActionButton
          disabled={selectedIndices.length === 0}
          onClick={removeSelected}
          styles={{ rootHovered: { color: theme.palette.redDark } }}
        >
          Delete
        </ActionButton>
        <ActionButton
          disabled={singleSelectedMiningOperation === undefined}
          onClick={openEditModal}
        >
          Edit
        </ActionButton>
      </Stack>

      <DetailsList
        columns={columns}
        items={form.miningOperations || []}
        selection={selection}
        layoutMode={DetailsListLayoutMode.justified}
        styles={{
          headerWrapper: {
            "& > div": {
              paddingTop: theme.spacing.s2,
            },
          },
        }}
      />

      {/* Add modal */}
      <Modal
        isOpen={showAddModal}
        styles={{ scrollableContent: { overflowY: "initial" } }}
      >
        {showAddModal && (
          <MiningOperationFormProvider>
            <MiningOperationAddForm
              addMiningOperation={addMiningOperation}
              closeModal={closeAddModal}
            />
          </MiningOperationFormProvider>
        )}
      </Modal>

      {/* Edit modal */}
      <Modal
        isOpen={showEditModal}
        styles={{ scrollableContent: { overflowY: "initial" } }}
      >
        {showEditModal && (
          <MiningOperationFormProvider
            miningOperationForm={singleSelectedMiningOperation}
          >
            <MiningOperationUpdateForm
              updateMiningOperation={updateMiningOperation}
              closeModal={closeEditModal}
            />
          </MiningOperationFormProvider>
        )}
      </Modal>
    </React.Fragment>
  );
};

type MiningOperationAddFormProps = ModalProps & {
  addMiningOperation: (minginOperationDto: MiningOperationDto) => void;
};

const MiningOperationAddForm: React.FC<MiningOperationAddFormProps> = (
  props
) => {
  const { miningOperationDto } = useContext(MiningOperationFormContext);
  const theme = useTheme();

  return (
    <MiningOperationFormContainer>
      <Text
        variant="large"
        styles={{
          root: {
            fontWeight: FontWeights.semibold,
          },
        }}
      >
        Add mining operation
      </Text>

      {/* Form */}
      <MiningOperationForm />

      {/* Actions */}
      <Stack
        className="ms-Grid"
        dir="ltr"
        styles={{ root: { marginTop: theme.spacing.m } }}
      >
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <Stack
              horizontal
              horizontalAlign="end"
              tokens={{ childrenGap: 10 }}
            >
              <PrimaryButton
                disabled={miningOperationDto === undefined}
                onClick={() =>
                  miningOperationDto &&
                  props.addMiningOperation(miningOperationDto)
                }
              >
                Add
              </PrimaryButton>
              <DefaultButton onClick={props.closeModal}>Cancel</DefaultButton>
            </Stack>
          </div>
        </div>
      </Stack>
    </MiningOperationFormContainer>
  );
};

type MiningOperationUpdateFormProps = ModalProps & {
  updateMiningOperation: (minginOperationDto: MiningOperationDto) => void;
};

const MiningOperationUpdateForm: React.FC<MiningOperationUpdateFormProps> = (
  props
) => {
  const { miningOperationDto } = useContext(MiningOperationFormContext);
  const theme = useTheme();

  return (
    <MiningOperationFormContainer>
      <Text
        variant="large"
        styles={{
          root: {
            fontWeight: FontWeights.semibold,
          },
        }}
      >
        Edit mining operation
      </Text>

      {/* Form */}
      <MiningOperationForm />

      {/* Actions */}
      <Stack
        className="ms-Grid"
        dir="ltr"
        styles={{ root: { marginTop: theme.spacing.m } }}
      >
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <Stack
              horizontal
              horizontalAlign="end"
              tokens={{ childrenGap: 10 }}
            >
              <PrimaryButton
                disabled={miningOperationDto === undefined}
                onClick={() =>
                  miningOperationDto &&
                  props.updateMiningOperation(miningOperationDto)
                }
              >
                Update
              </PrimaryButton>
              <DefaultButton onClick={props.closeModal}>Cancel</DefaultButton>
            </Stack>
          </div>
        </div>
      </Stack>
    </MiningOperationFormContainer>
  );
};

export default MonitoringExerciseForm;
