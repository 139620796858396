import React, { useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  FontWeights,
  Icon,
  IconFontSizes,
  IImageProps,
  Image,
  ImageFit,
  Link,
  Stack,
  Text,
  useTheme,
} from "@fluentui/react";
import { Container } from "@mui/system";
import { KeycloakProfile } from "keycloak-js";

import { useKeycloak } from "../KeycloakContext";
import logo from "./logo.png";

const getFullName = (userProfile: KeycloakProfile) =>
  [userProfile.firstName, userProfile.lastName]
    .filter((n) => Boolean(n))
    .join(" ");

const imageProps: IImageProps = {
  src: logo,
  imageFit: ImageFit.contain,
  width: 180,
};

const pages = [
  // { label: "Overview", to: "overview" },
  { label: "Monitoring", to: "monitoring" },
];

const Header: React.FC = () => {
  const [userProfile, setUserProfile] = useState<KeycloakProfile>();
  const theme = useTheme();
  const keycloak = useKeycloak();

  useEffect(() => {
    (async () => {
      const userProfile = await keycloak.loadUserProfile();
      setUserProfile(userProfile);
    })();
  }, [keycloak]);

  const navItems = useMemo(
    () =>
      pages.map(({ to, label }) => (
        <NavLink
          key={to}
          to={to}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {({ isActive }) => (
            <Text
              variant="mediumPlus"
              styles={{
                root: {
                  fontWeight: FontWeights.semibold,
                  paddingBottom: theme.spacing.s2,
                  borderBottom: isActive
                    ? `2px solid ${theme.palette.themeSecondary}`
                    : "none",
                },
              }}
            >
              {label}
            </Text>
          )}
        </NavLink>
      )),
    [theme]
  );

  return (
    <Stack
      styles={{
        root: {
          backgroundColor: theme.palette.neutralLighterAlt,
        },
      }}
    >
      <Container maxWidth="lg" sx={{ py: 6 }}>
        <Stack horizontal>
          <Stack horizontal tokens={{ childrenGap: 15 }}>
            <Image alt="Ministry of Natural Resources" {...imageProps} />

            <Stack verticalAlign="center" tokens={{ childrenGap: 15 }}>
              <Stack>
                <Text
                  variant="xxLarge"
                  styles={{ root: { fontWeight: FontWeights.bold } }}
                >
                  Ministry of Natural Resources
                </Text>
                <Text
                  variant="xLarge"
                  styles={{ root: { fontWeight: FontWeights.semibold } }}
                >
                  Compliance Department
                </Text>
                <Text variant="large">Management Information System</Text>
              </Stack>

              <Stack horizontal tokens={{ childrenGap: 20 }}>
                {navItems}
              </Stack>
            </Stack>
          </Stack>

          <Stack grow />

          {userProfile && (
            <Stack verticalAlign="center" horizontalAlign="center">
              <Icon
                iconName="Contact"
                styles={{
                  root: {
                    fontSize: IconFontSizes.large,
                    marginBottom: theme.spacing.s2,
                  },
                }}
              />
              <Text variant="medium">
                <Link
                  href={keycloak.createAccountUrl()}
                  styles={{
                    root: {
                      color: theme.palette.neutralDark,
                    },
                  }}
                >
                  {getFullName(userProfile)}
                </Link>
              </Text>
              <Text variant="medium">
                <Link
                  href={keycloak.createLogoutUrl()}
                  styles={{
                    root: {
                      color: theme.palette.neutralDark,
                      "&:hover": {
                        color: theme.palette.redDark,
                      },
                    },
                  }}
                >
                  Sign out
                </Link>
              </Text>
            </Stack>
          )}
        </Stack>
      </Container>
    </Stack>
  );
};

export default Header;
